import React from 'react'

import Seo from '@/components/Seo'
import Layout from '@/components/Layout'
import Header from '@/components/Header'

import MISION from '../images/solucion_1.jpg'
import VISION from '../images/solucion_2.jpg'
import HERO from '../images/hero_nosotros.jpg'
import ICON_SEGURIDAD from '../images/valores-calidad.png'
/* import ICON_POSTVENTA from '../images/valores-postventa.png' */
import ICON_SERVICIOS from '../images/valores-servicios.png'
/* import ICON_INNOVACION from '../images/valores-innovacion.png' */
import ICON_PUNTUALIDAD from '../images/valores-puntualidad.png'
import ICON_RESPONSABILIDAD from '../images/valores-responsabilidad.png'

const Nosotros = () => {
  return (
    <Layout pause>
      <Seo title="Nosotros"
        description='Almacén Rivera es una empresa con más de 10 años de experiencia dedica al almacenamiento para maquinarias pesadas y otros con un amplio deposito circulado con muros de concreto, seguridad permanente todo el día y noche para un mejor servicio a nuestros clientes.'
      />

      <Header
        image={HERO}
        title="Sobre"
        title2="nosotros"
        className="my-10 md:my-20 py-14 md:py-20"
        desc={`Almacén Rivera es una empresa con más de 10 años 
              de experiencia dedica al almacenamiento para maquinarias
              pesadas y otros con un amplio deposito circulado con muros de concreto, 
              seguridad permanente todo el día y noche para un mejor servicio a nuestros clientes.`}
      />
      <section className="max-w-6xl mx-auto px-5">
        {/* MISION Y VISION */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-20">
          <div>
            <h2 className="h3 mb-2">
              NUESTRA <span className="text-red-600">MISIÓN</span>
            </h2>

            <p className="leading-relaxed text-gray-500 mb-2">
              Brindar un servicio eficiente de almacenaje y depósitos en Lima, de todo tipo de mercaderías y bienes buscando satisfacer las necesidades de nuestros clientes.
            </p>

            <div className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
              <img
                src={MISION}
                alt="Almacen para guardar maquinaria"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          <div>
            <h2 className="h3 mb-2">
              NUESTRA <span className="text-red-600">VISIÓN</span>
            </h2>

            <p className="leading-relaxed text-gray-500 mb-2">
              Convertirnos en una empresa líder en el mercado de almacenaje y depósitos en Lima, permitiendo a nuestros clientes soluciones de espacio y seguridad.
            </p>

            <div className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
              <img
                src={VISION}
                alt="Almacen para guardar maquinaria"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
        </div>

        {/* VALORES */}
        <div className="w-full mb-14 md:mb-20">
          <h2 className="h3 mb-5 text-center">
            NUESTROS <span className="text-red-600">VALORES</span>
          </h2>

          <div className="w-full md:w-9/12 mx-auto justify-center grid grid-cols-2 gap-y-10">
            <div className="">
              <img
                src={ICON_SEGURIDAD}
                alt="Seguridad"
                className="w-24 h-24 mx-auto mb-2"
              />
              <p className="font-bold text-center">SEGURIDAD</p>
            </div>
            {/* <div className="">
              <img
                src={ICON_INNOVACION}
                alt="Innovacion"
                className="w-24 h-24 mx-auto mb-2"
              />
              <p className="font-bold text-center">INNOVACIÓN</p>
            </div> */}
            {/* <div className="">
              <img
                src={ICON_POSTVENTA}
                alt="Respaldo postventa"
                className="w-24 h-24 mx-auto mb-2"
              />
              <p className="font-bold text-center">RESPALDO POSTVENTA</p>
            </div> */}
            <div className="">
              <img
                src={ICON_PUNTUALIDAD}
                alt="Eficiencia"
                className="w-24 h-24 mx-auto mb-2"
              />
              <p className="font-bold text-center">EFICIENCIA</p>
            </div>
            <div className="">
              <img
                src={ICON_RESPONSABILIDAD}
                alt="Responsabilidad"
                className="w-24 h-24 mx-auto mb-2"
              />
              <p className="font-bold text-center">RESPONSABILIDAD</p>
            </div>
            <div className="">
              <img
                src={ICON_SERVICIOS}
                alt="Calidad de servicio"
                className="w-24 h-24 mx-auto mb-2"
              />
              <p className="font-bold text-center">CALIDAD DE SERVICIO</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Nosotros
